import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div id="disclosureText">
        </div>
        <div id="page">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="first has-sub-nav">
                    <a className="es_header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                  {/*<li className="nav-selected nav-path-selected">
                    <a className="es_header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/">UK</a>
                      </li>
                      <li>
                        <a href="/es/donde-has-estado/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/Por-Onde-Andaste/">PT</a>
                      </li>
                      <li>
                        <a href="/it/Ma-Dove-Sei-Stata/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/Where-Have-You-Been/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <div className="page-heritage__intro"></div>
            <section id="content">
              <div id="loading" />
              <div className="innerContent">
                <div
                  id="blockStyle4042Content127"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4042" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              padding: "30px 20px 0px 20px"
                            }}
                          >
                            <h1
                              style={{
                                color: "#f68e27",
                                "-webkit-text-align": "center",
                                "text-align": "center",
                                "line-height": "130%"
                              }}
                            >
                              Dietro una bella abbronzatura ci sono storie da
                              raccontare
                            </h1>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="HTMLBlock3982" className="HTMLBlock">
                  <hr
                    style={{
                      width: "250px",
                      "border-color": "#f68e27",
                      "border-style": "solid",
                      margin: "60px auto 60px auto"
                    }}
                  />
                </div>
                <div
                  id="blockStyle4084Content120"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4084" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                              className="w100pc"
                            >
                              <tbody>
                                <tr>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-left": "1.5%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td className="side-pad">
                                            <table
                                              border="0"
                                              cellPadding="0"
                                              cellSpacing="0"
                                              width="100%"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    style={{
                                                      "-webkit-text-transform":
                                                        "uppercase",
                                                      "text-transform":
                                                        "uppercase"
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        "font-weight": "100",
                                                        margin: "0 0 25px"
                                                      }}
                                                    >
                                                      Vuoi avere un'abbronzatura
                                                      mozzafiato? Assicurati di
                                                      essere protetta!
                                                    </h3>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td align="left">
                                                    <p>
                                                      Per molto tempo è esistito
                                                      un falso mito secondo il
                                                      quale per ottenere
                                                      un'abbronzatura rapida e
                                                      bella non era necessario
                                                      applicare una protezione
                                                      solare sulla pelle.
                                                      Sbagliato!{" "}
                                                      <a
                                                        href="/it/know_the_sun/"
                                                        style={{
                                                          color: "#f68e27"
                                                        }}
                                                        target="_blank"
                                                      >
                                                        Le radiazioni solari
                                                      </a>{" "}
                                                      dirette sulla pelle, non
                                                      solo sono pericolose per
                                                      la salute nel lungo tempo,
                                                      ma provocano
                                                      nell'immediato irritazioni
                                                      con il risultato che
                                                      l’abbronzatura svanisce
                                                      prima. Gli effetti dei
                                                      raggi ultravioletti A e B
                                                      (UVA/UVB) causano infatti
                                                      una pigmentazione cutanea
                                                      immediata, ma solo
                                                      transitoria.
                                                      <br />
                                                      <br />
                                                      La nostra pelle possiede
                                                      diversi meccanismi di
                                                      difesa contro le{" "}
                                                      <a
                                                        href="/it/know_the_sun/"
                                                        style={{
                                                          color: "#f68e27"
                                                        }}
                                                        target="_blank"
                                                      >
                                                        radiazioni solari
                                                      </a>
                                                      , il principale dei quali
                                                      è la produzione di
                                                      melanina. Questo pigmento
                                                      è responsabile, ad
                                                      esempio, del colore della
                                                      pelle, dei capelli e
                                                      dell'iride, ed è in grado
                                                      di filtrare in modo
                                                      naturale i raggi solari
                                                      dannosi. La pelle si
                                                      difende per prevenire
                                                      scottature e
                                                      danneggiamento dei nuclei
                                                      cellulari e come
                                                      conseguenza si abbronza.
                                                      E' molto importante
                                                      proteggerla mentre si
                                                      organizza per
                                                      auto-difendersi, solo così
                                                      si potra ottenere una
                                                      bella abbronzatura che
                                                      duri a lungo!
                                                    </p>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="HTMLBlock3983" className="HTMLBlock">
                  <hr
                    style={{
                      width: "250px",
                      "border-color": "#f68e27",
                      "border-style": "solid",
                      margin: "60px auto 60px auto"
                    }}
                  />
                </div>
                <div
                  id="blockStyle4085Content121"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4085" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                              className="w100pc"
                              dir="rtl"
                            >
                              <tbody>
                                <tr>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-left": "1.5%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td align="center">
                                            <img
                                              style={{
                                                "vertical-align": "text-top",
                                                "max-width": "100%",
                                                "max-height": "500px",
                                                "margin-bottom": "-70px"
                                              }}
                                              src="/assets/images/TP_30_15.jpg"
                                              alt
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-right": "1.5%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td className="side-pad">
                                            <table
                                              border="0"
                                              cellPadding="0"
                                              cellSpacing="0"
                                              width="100%"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td
                                                    align="left"
                                                    style={{
                                                      "-webkit-text-transform":
                                                        "uppercase",
                                                      "text-transform":
                                                        "uppercase"
                                                    }}
                                                  >
                                                    <h3
                                                      style={{
                                                        "font-weight": "100",
                                                        margin: "0 0 25px"
                                                      }}
                                                    >
                                                      Per un’abbronzatura più
                                                      rapida e bella... in tutta
                                                      sicurezza... Nuovo PIZ
                                                      BUIN Tan &amp; Protect®
                                                      Spray Solare acceleratore
                                                      dell’abbronzatura{" "}
                                                      <span
                                                        style={{
                                                          "font-size": "15px",
                                                          "vertical-align":
                                                            "top"
                                                        }}
                                                      >
                                                        ®
                                                      </span>
                                                    </h3>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td align="left">
                                                    <p>
                                                      La sua formula combina la
                                                      protezione UVA/UVB con
                                                      l'Illumitone, una
                                                      tecnologia che aumenta la
                                                      produzione di melanina
                                                      nella pelle, accelerando e
                                                      intensificando
                                                      l'abbronzatura naturale
                                                      più a lungo. L'innovativo
                                                      formato spray consente
                                                      un'applicazione
                                                      multiposizione, come gli
                                                      aerosol. Questo prodotto è
                                                      disponibile con SPF 15 e
                                                      SPF 30.
                                                    </p>
                                                    <p
                                                      style={{
                                                        "-webkit-text-align":
                                                          "left",
                                                        "text-align": "left",
                                                        "margin-left": "30px"
                                                      }}
                                                    >
                                                      Eccone tutti i vantaggi:
                                                    </p>
                                                    <ul
                                                      style={{
                                                        "-webkit-text-align":
                                                          "left",
                                                        "text-align": "left",
                                                        "list-style": "inherit",
                                                        "margin-left": "30px"
                                                      }}
                                                    >
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Rinforza il naturale
                                                        processo di abbronzatura
                                                        della pelle
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Offre una protezione
                                                        UVA/UVB immediata ed
                                                        efficace
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Si assorbe rapidamente
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        È facile da applicare
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Non unge e non appiccica
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Idrata la pelle (24 ore:
                                                        vedasi MT)
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        Aiuta a prevenire la
                                                        desquamazione e
                                                        contribuisce a
                                                        prolungare
                                                        l'abbronzatura
                                                      </li>
                                                      <li
                                                        style={{
                                                          "list-style":
                                                            "inherit"
                                                        }}
                                                      >
                                                        È resistente all'acqua e
                                                        al sudore
                                                      </li>
                                                    </ul>
                                                    <p
                                                      style={{
                                                        margin: "20px 0 0 0"
                                                      }}
                                                    >
                                                      <a
                                                        href="/it/prodotti/tan_protect/"
                                                        target="_blank"
                                                        style={{
                                                          "-webkit-text-decoration":
                                                            "none",
                                                          "text-decoration":
                                                            "none"
                                                        }}
                                                      >
                                                        Scopri tutti i prodotti
                                                        Piz Buin
                                                      </a>
                                                      <br />E non dimenticarti
                                                      di idratare la tua pelle
                                                      dopo aver preso il sole
                                                      con Piz Buin After Sun per
                                                      rivitalizzare la pelle
                                                      dopo il sole
                                                    </p>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div id="HTMLBlock3984" className="HTMLBlock">
                  <hr
                    style={{
                      width: "250px",
                      "border-color": "#f68e27",
                      "border-style": "solid",
                      margin: "60px auto 60px auto"
                    }}
                  />
                </div>
                <div
                  id="blockStyle4013Content122"
                  className=" ccm-block-styles"
                >
                  <div id="HTMLBlock4013" className="HTMLBlock">
                    <table
                      border="0"
                      cellPadding="0"
                      cellSpacing="0"
                      width="100%"
                      className="w100pc"
                      bgcolor="#ffffff"
                    >
                      <tbody>
                        <tr>
                          <td align="center" valign="top">
                            <table
                              border="0"
                              cellPadding="0"
                              cellSpacing="0"
                              width="100%"
                              className="w100pc"
                            >
                              <tbody>
                                <tr>
                                  <th
                                    align="left"
                                    valign="middle"
                                    style={{
                                      "font-weight": "normal",
                                      "vertical-align": "middle",
                                      margin: "0",
                                      padding: "0",
                                      width: "50%",
                                      "padding-right": "1.5%",
                                      "padding-bottom": "6%"
                                    }}
                                    className="block w100pc"
                                  >
                                    <table
                                      border="0"
                                      cellPadding="0"
                                      cellSpacing="0"
                                      width="100%"
                                      dir="ltr"
                                    >
                                      <tbody>
                                        <tr>
                                          <td align="center">
                                            <img
                                              style={{
                                                "vertical-align": "text-top",
                                                "max-width": "50%"
                                              }}
                                              src="/assets/images/SUN_PICTURES_left.jpg"
                                              alt
                                              width="100%"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
          <div id="product-details-overlay" className="itemDescription"></div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />
        
      </div>
    );
  }
}

export default Page;
